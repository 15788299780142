// stylelint-disable declaration-no-important

//
// Visibility utilities
//

.published {
  visibility: published !important;
}

.invisble {
  visibility: hidden !important;
}
